import React, { Component } from 'react'
import styles from './index.module.css'
import { axiosPost, axiosPUT } from '../../Utils/RequestUtil'
import { fileResizetoFile } from '../../Utils/FileResizetoFile'
import { Button, message, Modal, Spin, Alert } from 'antd'
import { validateIdCard, validateMobilePhone, validateEmail, validateNumber, validateChinese, validatePhone, validateBankId } from '../../assets/regs/regs'
import update from 'immutability-helper'
import moment from 'moment'

import gobackImg from '../../assets/images/goback.png'

import Cascader from '../../components/Cascader/index'
import Child from '../../components/Child/index'
import Date from '../../components/Date/index'
import File from '../../components/File/index'
import LongRadio from '../../components/LongRadio/index'
import ShortRadio from '../../components/ShortRadio/index'
import ShortText from '../../components/ShortText/index'
import Image from '../../components/Image/index'
import AddMenber from '../AddMenber/index'
import Sign from '../../components/Sign'
import ShortTextMobile from '../../components/ShortTextMobile'
import DateMobile from '../../components/DateMobile'
import LongRadioMobile from '../../components/LongRadioMobile'
import CascaderMobile from '../../components/CascaderMobile'
import ImageMobile from '../../components/ImageMobile'
import ChildMobile from '../../components/ChildMobile'

export default class WriteHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            key: '',
            area: '',
            questions: [],
            forms: [],
            loading: false,
            showAddPage: false,
            addKey: 0,
            formdataData: [],
            addIndex: -1,
            status: '',
            lookChildIndex: -1,
            isWrite: true,
            warnMessage: ''
        }
    }

    componentDidMount() {
        const key = this.props.match.params.key
        const area = this.props.match.params.area
        const status = this.props.match.params.status
        const title = this.props.match.params.title
        // console.log(key, area, status)
        const isWrite = status === '00' || status === '03' ? true : false
        // console.log(isWrite)
        if (key === 'zzdjxxb') {
            this.getQuestionInfo(area)
            this.setState({
                key: '',
                area: area,
                status: status,
                title: title,
                isWrite: isWrite
            })
        } else {
            this.getWriteInfo(key, area)
            this.setState({
                key: key,
                area: area,
                status: status,
                title: title,
                isWrite: isWrite
            })
        }
    }
    getQuestionInfo = (area) => {
        const params = {
            area: area
        }
        this.setState({
            loading: true
        })
        axiosPost(4, params, 'Field', (res) => {
            this.setState({
                loading: false
            })
            if (res.code === 0) {
                let forms = []
                let formdataData = []
                for (let i = 0; i < res.data.item.questions.length; i++) {
                    if (res.data.item.questions[i].type === '15') {
                        forms.push({
                            key: res.data.item.questions[i].key,
                            type: res.data.item.questions[i].type,
                            answer: [],
                            isDisable: false
                        })
                    } else if (res.data.item.questions[i].type === '11' || res.data.item.questions[i].type === '12') {
                        formdataData.push({
                            index: res.data.item.questions[i].key,
                            files: [],
                            isDisable: false
                        })
                        forms.push({
                            key: res.data.item.questions[i].key,
                            type: res.data.item.questions[i].type,
                            answer: '',
                            isDisable: false
                        })
                    } else {
                        forms.push({
                            key: res.data.item.questions[i].key,
                            type: res.data.item.questions[i].type,
                            answer: '',
                            isDisable: false
                        })
                    }
                }
                this.setState({
                    questions: res.data.item.questions,
                    forms: forms,
                    formdataData: formdataData
                })
            }
        })
    }

    getWriteInfo = (key, area) => {
        // console.log(key, area)
        const params = {
            area: area,
            key: key
        }
        this.setState({
            loading: true
        })
        // console.log(params)
        axiosPost(4, params, 'Form', (res) => {
            console.log(res)
            this.setState({
                loading: false
            })
            if (res.code === 0) {
                let forms = []
                let formdataData = []
                for (let i = 0; i < res.data.item.questions.length; i++) {
                    if (res.data.item.questions[i].type === '15') {
                        if (res.data.item.questions[i].answer.submitAnswer !== '') {
                            let answer1 = JSON.parse(res.data.item.questions[i].answer.submitAnswer)
                            let newAnswer = []
                            for (let j = 0; j < answer1.length; j++) {
                                let newAnswer1 = []
                                for (let k = 0; k < answer1[j].data.length; k++) {
                                    newAnswer1.push({
                                        key: answer1[j].data[k].key,
                                        type: answer1[j].data[k].type,
                                        answer: answer1[j].data[k].answer.submitAnswer
                                    })
                                }
                                newAnswer.push({
                                    data: newAnswer1
                                })
                            }
                            forms.push({
                                key: res.data.item.questions[i].key,
                                type: res.data.item.questions[i].type,
                                answer: newAnswer
                            })
                        } else {
                            forms.push({
                                key: res.data.item.questions[i].key,
                                type: res.data.item.questions[i].type,
                                answer: []
                            })
                        }

                        // forms.push({
                        //     key: res.data.item.questions[i].key,
                        //     type: res.data.item.questions[i].type,
                        //     answer: JSON.parse(res.data.item.questions[i].answer.submitAnswer)
                        // })

                    } else if (res.data.item.questions[i].type === '11' || res.data.item.questions[i].type === '12') {
                        formdataData.push({
                            index: res.data.item.questions[i].key,
                            files: []
                        })
                        forms.push({
                            key: res.data.item.questions[i].key,
                            type: res.data.item.questions[i].type,
                            answer: res.data.item.questions[i].answer.submitAnswer
                        })
                    } else {
                        forms.push({
                            key: res.data.item.questions[i].key,
                            type: res.data.item.questions[i].type,
                            answer: res.data.item.questions[i].answer.submitAnswer
                        })
                    }
                }
                this.setState({
                    questions: res.data.item.questions,
                    forms: forms,
                    formdataData: formdataData
                })
            }
            // this.setState({
            //     answersQuestions: res.data.item.questions
            // })
        })
    }

    hideAddPage = () => {
        this.setState({
            showAddPage: false
        })
    }

    addMenber = (key, index) => {
        this.setState({
            showAddPage: true,
            addKey: key,
            addIndex: index,
            lookChildIndex: -1
        })
    }
    addMenberInfo = (info, addKey, lookChildIndex) => {
        const menberData = {
            data: info
        }
        // console.log(info, addKey, lookChildIndex)
        // console.log(this.state.forms)
        const formsKeyArr = []
        for (let i = 0; i < this.state.forms.length; i++) {
            formsKeyArr.push(this.state.forms[i].key)
        }
        const formIndex = formsKeyArr.indexOf(addKey)
        let newAnswer
        if (lookChildIndex === -1) {
            newAnswer = update(this.state.forms[formIndex], { answer: { $push: [menberData] } })
        } else {
            newAnswer = update(this.state.forms[formIndex], { answer: { $splice: [[lookChildIndex, 1, menberData]] } })
        }
        console.log(newAnswer)
        const newForms = update(this.state.forms, { $splice: [[formIndex, 1, newAnswer]] })
        console.log(newForms)
        this.setState({
            forms: newForms,
            showAddPage: false
        })
    }

    lookChild = (childIndex, key, questionIndex) => {
        this.setState({
            showAddPage: true,
            addKey: key,
            addIndex: questionIndex,
            lookChildIndex: childIndex,
        })
    }

    getItemAnswer = (e, index) => {
        let newForm = update(this.state.forms, { $splice: [[index, 1, e]] });
        if (this.state.questions[index].validate === "04") {
            if (e.answer.length == 18) {
                axiosPost(24, { enterprise: this.state.area, idCard: e.answer }, 'Form', (res) => {
                    if (res.code === 0) {
                        res.data.list.forEach(item => {
                            this.state.questions.forEach((qItem, qIndex) => {
                                if (qItem.key === item.key) {
                                    let autoValue = {
                                        key: qItem.key,
                                        type: qItem.type,
                                        answer: item.value,
                                        isDisable: !item.allowModify
                                    }
                                    newForm = update(newForm, { $splice: [[qIndex, 1, autoValue]] });
                                }
                            })
                        })
                    }
                });
            } else {
                let needRefresh = false
                for (let index = 0; index < this.state.forms.length; index++) {
                    if (this.state.forms[index].isDisable) {
                        needRefresh = true
                        break
                    }
                }
                if (needRefresh) {
                    let newForm = update(this.state.forms, { $splice: [[index, 1, e]] });
                    newForm.forEach(item => item.isDisable = false)
                }
            }
        }
        this.setState({ forms: newForm });
    }

    goback = () => {
        this.props.history.go(-1)
    }

    formDataUpData = (files, index) => {
        const { formdataData } = this.state
        const data = {
            index: index,
            files: files
        }
        const indexArr = []
        for (let i = 0; i < formdataData.length; i++) {
            indexArr.push(formdataData[i].index)
        }
        const dataIndex = indexArr.indexOf(index)
        // console.log(dataIndex)
        if (dataIndex > -1) {
            const newData = update(formdataData, { $splice: [[dataIndex, 1, data]] })
            this.setState({
                formdataData: newData
            })
        } else {
            const newData = update(formdataData, { $push: [data] })
            this.setState({
                formdataData: newData
            })
        }
    }

    submitForm = () => {
        const { forms, formdataData, questions, area, key } = this.state
        console.log(forms)
        console.log(formdataData)
        const keysArr = []
        for (let i = 0; i < questions.length; i++) {
            keysArr.push(questions[i].key)
        }
        for (let i = 0; i < forms.length; i++) {
            const itemKey = forms[i].key
            const itemAnswer = forms[i].answer
            const itemType = forms[i].type
            const itemIndex = keysArr.indexOf(itemKey)
            const isRequire = questions[itemIndex].require
            const validateType = questions[itemIndex].validate
            if (isRequire === '1') {
                if (itemType === '15') {
                    if (itemAnswer.length === 0) {
                        message.error(`${questions[itemIndex].desc}为必填项，暂未填写`)
                        return
                    }
                } else if (itemType === '11' || itemType === '12') {
                    if (itemAnswer === '') {
                        const dataIndexArr = []
                        for (let i = 0; i < formdataData.length; i++) {
                            dataIndexArr.push(formdataData[i].index)
                        }
                        const answerIndex = dataIndexArr.indexOf(itemKey)
                        if (formdataData[answerIndex].files.length === 0) {
                            message.error(`${questions[itemIndex].desc}为必填项，暂未填写`)
                            return
                        }
                    }
                } else if (itemType === '13') {
                    const cascadeAnswer = itemAnswer.split(',')
                    for (let i = 0; i < cascadeAnswer.length; i++) {
                        if (cascadeAnswer[i] === '') {
                            message.error(`${questions[itemIndex].desc}需要填写完整`)
                            return
                        }
                    }
                } else {
                    if (itemAnswer === '') {
                        message.error(`${questions[itemIndex].desc}为必填项，暂未填写`)
                        return
                    } else {
                        let result
                        switch (validateType) {
                            case '02':
                                result = validateMobilePhone(itemAnswer)
                                break;
                            case '03':
                                result = validatePhone(itemAnswer)
                                break;
                            case '04':
                                result = validateIdCard(itemAnswer)
                                break;
                            case '05':
                                result = validateEmail(itemAnswer)
                                break;
                            case '06':
                                result = validateBankId(itemAnswer)
                                break;
                            case '07':
                                result = validateNumber(itemAnswer)
                                break;
                            case '08':
                                result = validateChinese(itemAnswer)
                                break;
                            default:
                                result = true
                                break;
                        }
                        if (!result) {
                            message.error(`${questions[itemIndex].desc}填写格式不正确，请仔细检查`)
                            return
                        }
                    }
                }
            } else {
                if (itemAnswer !== '') {
                    let result
                    switch (validateType) {
                        case '02':
                            result = validateMobilePhone(itemAnswer)
                            break;
                        case '03':
                            result = validatePhone(itemAnswer)
                            break;
                        case '04':
                            result = validateIdCard(itemAnswer)
                            break;
                        case '05':
                            result = validateEmail(itemAnswer)
                            break;
                        case '06':
                            result = validateBankId(itemAnswer)
                            break;
                        case '07':
                            result = validateNumber(itemAnswer)
                            break;
                        case '08':
                            result = validateChinese(itemAnswer)
                            break;
                        default:
                            result = true
                            break;
                    }
                    if (!result) {
                        message.error(`${questions[itemIndex].desc}填写格式不正确，请仔细检查`)
                        return
                    }
                }
            }
        }

        // 压缩操作
        let newFormData = []
        let allCount = 0
        for (let i = 0; i < formdataData.length; i++) {
            allCount += formdataData[i].files.length
        }
        console.log(allCount)
        this.setState({
            loading: true,
            warnMessage: '正在上传，请稍等....'
        })
        if (allCount !== 0) {
            for (let i = 0; i < formdataData.length; i++) {
                for (let j = 0; j < formdataData[i].files.length; j++) {
                    const item = formdataData[i].files[j]
                    fileResizetoFile(item, (res) => {
                        newFormData.push({
                            key: j,
                            index: formdataData[i].index,
                            file: res
                        })
                        // 压缩完成发送请求
                        if (newFormData.length === allCount) {
                            console.log(newFormData)
                            const openId = sessionStorage.getItem('openId')
                            const params = {
                                action: 2,
                                parameters: {
                                    area: area,
                                    open: openId,
                                    relkey: '85738e77-53c6-4d06-a2c4-db63eb9dca6a',
                                    key: key,
                                    forms: forms
                                }
                            }
                            var form1 = new FormData()
                            form1.append("json", JSON.stringify(params))
                            for (let k = 0; k < newFormData.length; k++) {
                                var file = newFormData[k].file;
                                var ext = file.name.split('.');
                                var extStr = ext[ext.length - 1];
                                var filename = newFormData[k].index + "_" + newFormData[k].key + "." + extStr;
                                form1.append("file", file, filename)
                            }

                            axiosPUT(form1, 'Form', (res) => {
                                console.log(res)
                                this.setState({
                                    loading: false,
                                    warnMessage: ''
                                })
                                if (res.code === 0) {
                                    message.success('提交成功')
                                    this.props.history.go(-1)
                                } else {
                                    message.error(res.message)
                                }
                            })
                        }
                    })
                }
            }
        } else {
            const openId = sessionStorage.getItem('openId')
            const params = {
                action: 2,
                parameters: {
                    area: area,
                    open: openId,
                    relkey: '85738e77-53c6-4d06-a2c4-db63eb9dca6a',
                    key: key,
                    forms: forms
                }
            }
            var form1 = new FormData()
            form1.append("json", JSON.stringify(params))
            axiosPUT(form1, 'Form', (res) => {
                console.log(res)
                this.setState({
                    loading: false,
                    warnMessage: ''
                })
                if (res.code === 0) {
                    message.success('提交成功')
                    this.props.history.go(-1)
                } else {
                    message.error('提交失败')
                }
            })
        }


        // const openId = sessionStorage.getItem('openId')
        // const params = {
        //     action: 2,
        //     parameters: {
        //         area: area,
        //         open: openId,
        //         relkey: '85738e77-53c6-4d06-a2c4-db63eb9dca6a',
        //         key: key,
        //         forms: forms
        //     }
        // }

        // var form1 = new FormData()
        // form1.append("json", JSON.stringify(params))
        // console.log(params)
        // for (let i = 0; i < formdataData.length; i++) {
        //     for (let j = 0; j < formdataData[i].files.length; j++) {
        //         var file = formdataData[i].files[j];
        //         var ext = file.name.split('.');
        //         var extStr = ext[ext.length - 1];
        //         var filename = formdataData[i].index + "_" + j + "." + extStr;
        //         form1.append("file", file, filename)
        //     }
        // }
        // this.setState({
        //     loading: true
        // })
        // axiosPUT(form1, 'Form', (res) => {
        //     console.log(res)
        //     this.setState({
        //         loading: false
        //     })
        //     if (res.code === 0) {
        //         message.success('提交成功')
        //         this.props.history.go(-1)
        //     } else {
        //         message.error('提交失败')
        //     }
        // })
    }

    makeSure = () => {
        const { forms, formdataData } = this.state
        console.log(forms)
        console.log(formdataData)
        this.setState({
            visible: true
        })
    }

    handleOk = () => {

        this.setState({
            visible: false
        })
        this.submitForm()
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    render() {
        const { questions, showAddPage, addKey, addIndex, forms, lookChildIndex, title, isWrite, warnMessage } = this.state
        return (
            <div className={styles.body}>
                <Spin spinning={this.state.loading}>
                    <div className={styles.header}>
                        <div className={styles.gobackImgBox} onClick={this.goback}>
                            <img src={gobackImg} alt='' />
                        </div>
                        <span className={styles.title}>{title}</span>
                    </div>
                    <div>
                        <div>
                            {questions.length > 0 ? questions.map((item, index) => <div key={item.key} style={{ borderBottom: '1px solid #eee' }}>
                                {
                                    (
                                        item.type === '01' ? <ShortTextMobile index={index} isWrite={isWrite && !forms[index].isDisable} answer={forms[index].answer} getItemAnswer={this.getItemAnswer} data={item}></ShortTextMobile> :
                                            item.type === '03' ? <DateMobile index={index} isWrite={isWrite && !forms[index].isDisable} answer={forms[index].answer} getItemAnswer={this.getItemAnswer} data={item}></DateMobile> :
                                                item.type === '06' ? <LongRadioMobile index={index} isWrite={isWrite && !forms[index].isDisable} answer={forms[index].answer} getItemAnswer={this.getItemAnswer} data={item}></LongRadioMobile> :
                                                    item.type === '08' ? <LongRadioMobile index={index} isWrite={isWrite && !forms[index].isDisable} answer={forms[index].answer} getItemAnswer={this.getItemAnswer} data={item}></LongRadioMobile> :
                                                        item.type === '11' ? <ImageMobile index={index} isWrite={isWrite && !forms[index].isDisable} answer={forms[index].answer} formDataUpData={this.formDataUpData} getItemAnswer={this.getItemAnswer} data={item}></ImageMobile> :
                                                            item.type === '12' ? <File index={index} isWrite={isWrite && !forms[index].isDisable} answer={forms[index].answer} formDataUpData={this.formDataUpData} getItemAnswer={this.getItemAnswer} data={item} history={this.props.history}></File> :
                                                                item.type === '13' ? <CascaderMobile index={index} isWrite={isWrite && !forms[index].isDisable} answer={forms[index].answer} getItemAnswer={this.getItemAnswer} data={item}></CascaderMobile> :
                                                                    item.type === '15' ? <ChildMobile index={index} isWrite={isWrite && !forms[index].isDisable} answer={JSON.stringify(forms[index].answer)} lookChild={this.lookChild} addMenber={this.addMenber} getItemAnswer={this.getItemAnswer} data={item}></ChildMobile> : 'Empty'
                                    )
                                }
                            </div>) : ''}
                        </div>
                        <div style={{ display: warnMessage === '' ? 'none' : 'block' }}><Alert message={warnMessage} type="success" /></div>
                        <div className={styles.submit} style={{ display: isWrite ? 'block' : 'none' }}>
                            <Button type='primary' style={{ width: '100%' }} onClick={this.makeSure}>提交</Button>
                        </div>
                    </div>
                    {showAddPage ?
                        <div className={styles.addMenberBox}>
                            <AddMenber
                                hideAddPage={this.hideAddPage}
                                addKey={addKey}
                                lookChildIndex={lookChildIndex}
                                questions={questions[addIndex].childrens}
                                answers={lookChildIndex === -1 ? [] : forms[addIndex].answer[lookChildIndex].data}
                                addMenberInfo={this.addMenberInfo}
                                isWrite={isWrite}
                            >

                            </AddMenber>
                        </div> : ''
                    }
                    <Modal
                        title="确认"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        提交后不可更改，是否提交
                    </Modal>
                </Spin>
            </div>
        )
    }
}