import React, { Component } from 'react'
import styles from './index.module.css'
import update from 'immutability-helper'

import addMenberImg from '../../assets/images/addMenber.png'

export default class Child extends Component {
    constructor(props) {
        super(props)
        // console.log(this.props)
        this.state = {
            answer: [],
            index: this.props.index,
            data: this.props.data,
            desc: this.props.data.desc,
            count: this.props.data.count,
            require: this.props.data.require,
            isWrite:this.props.isWrite
        }
    }

    componentDidMount() {
        let answer = JSON.parse(this.props.answer)
        if (answer.length > 0) {
            this.setState({
                answer: answer
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        let answer = JSON.parse(nextProps.answer)
        // console.log(answer)
        if (answer.length > 0) {
            this.setState({
                answer: answer
            })
        }
    }

    goAddMenber = () => {
        this.props.addMenber(this.state.data.key, this.state.index)
    }

    deleteChild = (e) => {
        const { answer } = this.state
        const newAnswer = update(answer, { $splice: [[e, 1]] })
        const data = {
            key: this.state.data.key,
            type: this.state.data.type,
            answer: newAnswer
        }
        this.setState({
            answer: newAnswer
        })
        console.log(data)
        this.props.getItemAnswer(data, this.state.index)
    }

    lookChild = (index) => {
        this.props.lookChild(index, this.state.data.key, this.state.index)
    }

    render() {
        const { desc, count, require, answer,isWrite } = this.state
        const countArr = count.split(',')
        return (
            <div>
                <div className={styles.title}>
                    <span style={{ color: 'red', display: require === '1' ? 'inline-block' : 'none', marginRight: '5px' }}>*</span>
                    <span>{desc}</span>
                    <span style={{ float: 'right' }}>数量{`${countArr[0]}~${countArr[1]}`}</span>
                </div>
                <div className={styles.addMenber} onClick={this.goAddMenber} style={{display:isWrite?'block':'none'}}>
                    <img src={addMenberImg} alt='' />
                </div>
                <div>
                    {answer.length > 0 ? answer.map((item, index) =>
                        <div key={index} className={styles.childList}>
                            <span onClick={() => this.lookChild(index)} style={{padding:'0 15px'}}>{item.data[2].answer}</span>
                            <span className={styles.delete} onClick={() => this.deleteChild(index)} style={{display:isWrite?'block':'none'}}>×</span>
                        </div>) : ''}
                </div>
            </div>
        )
    }
}