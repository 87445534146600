import React, { Component } from 'react'
import styles from './index.module.css'
import { Row, Col, Button, Card } from 'antd'
import { DeleteOutlined } from '@ant-design/icons';
import update from 'immutability-helper'

import addMenberImg from '../../assets/images/addMenber.png'

export default class ChildMobile extends Component {
    constructor(props) {
        super(props)
        // console.log(this.props)
        this.state = {
            answer: [],
            index: this.props.index,
            data: this.props.data,
            desc: this.props.data.desc,
            count: this.props.data.count,
            require: this.props.data.require,
            isWrite: this.props.isWrite
        }
    }

    componentDidMount() {
        let answer = JSON.parse(this.props.answer)
        if (answer.length > 0) {
            this.setState({
                answer: answer
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ isWrite: nextProps.isWrite })
        let answer = JSON.parse(nextProps.answer)
        if (answer.length > 0) {
            this.setState({
                answer: answer
            })
        }
    }

    goAddMenber = () => {
        this.props.addMenber(this.state.data.key, this.state.index)
    }

    deleteChild = (e) => {
        const { answer } = this.state
        const newAnswer = update(answer, { $splice: [[e, 1]] })
        const data = {
            key: this.state.data.key,
            type: this.state.data.type,
            answer: newAnswer
        }
        this.setState({
            answer: newAnswer
        })
        console.log(data)
        this.props.getItemAnswer(data, this.state.index)
    }

    lookChild = (index) => {
        this.props.lookChild(index, this.state.data.key, this.state.index)
    }

    render() {
        const { desc, count, require, answer, isWrite } = this.state
        const countArr = count.split(',')
        return (
            <div>
                <Row>
                    <Col span={18} style={{ backgroundColor: '#fff', fontSize: '18px', paddingLeft: '15px', color: '#000', lineHeight: '50px' }}>
                        <span style={{ color: 'red', display: require === '1' ? 'inline-block' : 'none', marginRight: '5px' }}>*</span>
                        <span style={{ fontSize: '14px' }}>{desc}</span>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right', backgroundColor: '#fff', paddingRight: '15px' }}>
                        <div className={styles.uploadBox} onClick={this.goAddMenber} style={{ display: isWrite ? 'block' : 'none', float: 'right' }}>
                            <img src={addMenberImg} alt='' style={{ height: '50px', width: '50px' }} />
                        </div>
                    </Col>
                </Row>
                <Row style={{ backgroundColor: '#fff' }}>
                    {answer.length > 0 ? answer.map((item, index) =>
                    (
                        <Col span="12" style={{ padding: '15px', paddingTop: '0px' }}>
                            <div key={index} className={styles.childList}>
                                <span onClick={() => this.lookChild(index)} style={{ padding: '0 15px' }}>{item.data[2].answer}</span>
                                <span className={styles.delete} onClick={() => this.deleteChild(index)} style={{ display: isWrite ? 'block' : 'none' }}>×</span>
                            </div>
                        </Col>
                    )
                    ) : ''}
                </Row>
            </div>
        )
    }
}