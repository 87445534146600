import React, { Component } from 'react'
import { Button, Spin, Modal, message } from 'antd'
import styles from './index.module.css'
import { axiosPost, axiosPUT } from '../../Utils/RequestUtil'
import { validateIdCard, validateMobilePhone, validateEmail, validateNumber, validateChinese, validatePhone, validateBankId } from '../../assets/regs/regs'
import update from 'immutability-helper'

import Cascader from '../../components/Cascader/index'
import Child from '../../components/Child/index'
import Date from '../../components/Date/index'
import File from '../../components/File/index'
import LongRadio from '../../components/LongRadio/index'
import ShortRadio from '../../components/ShortRadio/index'
import ShortText from '../../components/ShortText/index'
import Image from '../../components/Image/index'
import AddMenber from '../AddMenber/index'


export default class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            questions: [],
            forms: [],
            area: this.props.area,
            loading: false,
            showAddPage: false,
            addKey: 0,
            formdataData: [],
            addIndex: -1,
            visible: false
        }
    }

    componentDidMount() {
        console.log(this.props.area)
        this.getField(this.props.area)
    }

    getField = (e) => {
        const params = {
            area: e
        }
        this.setState({
            loading: true
        })
        axiosPost(4, params, 'Field', (res) => {
            this.setState({
                loading: false
            })
            console.log(res)
            if (res.code === 0) {
                let forms = []
                let formdataData = []
                for (let i = 0; i < res.data.item.questions.length; i++) {
                    if (res.data.item.questions[i].type === '15') {
                        forms.push({
                            key: res.data.item.questions[i].key,
                            type: res.data.item.questions[i].type,
                            answer: []
                        })

                    } else if (res.data.item.questions[i].type === '11' || res.data.item.questions[i].type === '12') {
                        formdataData.push({
                            index: res.data.item.questions[i].key,
                            files: []
                        })
                        forms.push({
                            key: res.data.item.questions[i].key,
                            type: res.data.item.questions[i].type,
                            answer: ''
                        })
                    } else {
                        forms.push({
                            key: res.data.item.questions[i].key,
                            type: res.data.item.questions[i].type,
                            answer: ''
                        })
                    }
                }
                this.setState({
                    questions: res.data.item.questions,
                    forms: forms,
                    formdataData: formdataData
                })
            }
        })
    }

    addMenber = (key, index) => {
        this.setState({
            showAddPage: true,
            addKey: key,
            addIndex: index
        })
    }

    hideAddPage = () => {
        this.setState({
            showAddPage: false
        })
    }

    addMenberInfo = (info, addKey) => {
        const menberData = {
            data: info
        }
        console.log(info, addKey)
        console.log(this.state.forms)
        const formsKeyArr = []
        for (let i = 0; i < this.state.forms.length; i++) {
            formsKeyArr.push(this.state.forms[i].key)
        }
        const formIndex = formsKeyArr.indexOf(addKey)
        const newAnswer = update(this.state.forms[formIndex], { answer: { $push: [menberData] } })
        console.log(newAnswer)
        const newForms = update(this.state.forms, { $splice: [[formIndex, 1, newAnswer]] })
        console.log(newForms)
        this.setState({
            forms: newForms,
            showAddPage: false
        })
    }

    getItemAnswer = (e, index) => {
        const newForm = update(this.state.forms, { $splice: [[index, 1, e]] })
        if (newForm !== undefined) {
            this.setState({
                forms: newForm
            })
        }
    }

    formDataUpData = (files, index) => {
        const { formdataData } = this.state
        const data = {
            index: index,
            files: files
        }
        const indexArr = []
        for (let i = 0; i < formdataData.length; i++) {
            indexArr.push(formdataData[i].index)
        }
        const dataIndex = indexArr.indexOf(index)
        console.log(dataIndex)
        if (dataIndex > -1) {
            const newData = update(formdataData, { $splice: [[dataIndex, 1, data]] })
            this.setState({
                formdataData: newData
            })
        } else {
            const newData = update(formdataData, { $push: [data] })
            this.setState({
                formdataData: newData
            })
        }
    }

    submitForm = () => {
        const { forms, formdataData, questions, area } = this.state
        console.log(forms)
        console.log(formdataData)
        const keysArr = []
        for (let i = 0; i < questions.length; i++) {
            keysArr.push(questions[i].key)
        }
        for (let i = 0; i < forms.length; i++) {
            const itemKey = forms[i].key
            const itemAnswer = forms[i].answer
            const itemType = forms[i].type
            const itemIndex = keysArr.indexOf(itemKey)
            const isRequire = questions[itemIndex].require
            const validateType = questions[itemIndex].validate
            if (isRequire === '1') {
                if (itemType === '15') {
                    if (itemAnswer.length === 0) {
                        message.error(`${questions[itemIndex].desc}为必填项，暂未填写`)
                        return
                    }
                } else if (itemType === '11' || itemType === '12') {
                    const dataIndexArr = []
                    for (let i = 0; i < formdataData.length; i++) {
                        dataIndexArr.push(formdataData[i].index)
                    }
                    const answerIndex = dataIndexArr.indexOf(itemKey)
                    if (formdataData[answerIndex].files.length === 0) {
                        message.error(`${questions[itemIndex].desc}为必填项，暂未填写`)
                        return
                    }
                } else if (itemType === '13') {
                    const cascadeAnswer = itemAnswer.split(',')
                    for (let i = 0; i < cascadeAnswer.length; i++) {
                        if (cascadeAnswer[i] === '') {
                            message.error(`${questions[itemIndex].desc}需要填写完整`)
                            return
                        }
                    }
                } else {
                    if (itemAnswer === '') {
                        message.error(`${questions[itemIndex].desc}为必填项，暂未填写`)
                        return
                    } else {
                        let result
                        switch (validateType) {
                            case '02':
                                result = validateMobilePhone(itemAnswer)
                                break;
                            case '03':
                                result = validatePhone(itemAnswer)
                                break;
                            case '04':
                                result = validateIdCard(itemAnswer)
                                break;
                            case '05':
                                result = validateEmail(itemAnswer)
                                break;
                            case '06':
                                result = validateBankId(itemAnswer)
                                break;
                            case '07':
                                result = validateNumber(itemAnswer)
                                break;
                            case '08':
                                result = validateChinese(itemAnswer)
                                break;
                            default:
                                result = true
                                break;
                        }
                        if (!result) {
                            message.error(`${questions[itemIndex].desc}填写格式不正确，请仔细检查`)
                            return
                        }
                    }
                }
            } else {
                if (itemAnswer !== '') {
                    let result
                    switch (validateType) {
                        case '02':
                            result = validateMobilePhone(itemAnswer)
                            break;
                        case '03':
                            result = validatePhone(itemAnswer)
                            break;
                        case '04':
                            result = validateIdCard(itemAnswer)
                            break;
                        case '05':
                            result = validateEmail(itemAnswer)
                            break;
                        case '06':
                            result = validateBankId(itemAnswer)
                            break;
                        case '07':
                            result = validateNumber(itemAnswer)
                            break;
                        case '08':
                            result = validateChinese(itemAnswer)
                            break;
                        default:
                            result = true
                            break;
                    }
                    if (!result) {
                        message.error(`${questions[itemIndex].desc}填写格式不正确，请仔细检查`)
                        return
                    }
                }
            }
        }
        const openId = sessionStorage.getItem('openId')
        const params = {
            action: 2,
            parameters: {
                area: area,
                open: openId,
                relkey: '85738e77-53c6-4d06-a2c4-db63eb9dca6a',
                key: '',
                forms: forms
            }
        }

        var form1 = new FormData()
        form1.append("json", JSON.stringify(params))
        console.log(params)
        for (let i = 0; i < formdataData.length; i++) {
            for (let j = 0; j < formdataData[i].files.length; j++) {
                var file = formdataData[i].files[j];
                var ext = file.name.split('.');
                var extStr = ext[ext.length - 1];
                var filename = formdataData[i].index + "_" + j + "." + extStr;
                form1.append("file", file, filename)
            }
        }
        this.setState({
            loading: true
        })
        axiosPUT(form1, 'Form', (res) => {
            console.log(res)
            this.setState({
                loading: false
            })
            if (res.code === 0) {
                message.success('提交成功')
                this.props.successSub()
            } else {
                message.error('提交失败')
            }
        })
    }

    makeSure = () => {
        this.setState({
            visible: true
        })
    }

    handleOk = () => {

        this.setState({
            visible: false
        })
        this.submitForm()
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    render() {
        const { questions, showAddPage, addKey, addIndex, forms } = this.state
        return (
            <Spin spinning={this.state.loading}>
                <div className={styles.body}>
                    <div className={styles.questionsBox}>
                        {questions.map((item, index) => <div className={styles.questionsItemBox} key={item.key}>
                            {
                                (
                                    item.type === '01' ? <ShortText index={index} getItemAnswer={this.getItemAnswer} data={item}></ShortText> :
                                        item.type === '03' ? <Date index={index} getItemAnswer={this.getItemAnswer} data={item}></Date> :
                                            item.type === '06' ? <ShortRadio index={index} getItemAnswer={this.getItemAnswer} data={item}></ShortRadio> :
                                                item.type === '08' ? <LongRadio index={index} getItemAnswer={this.getItemAnswer} data={item}></LongRadio> :
                                                    item.type === '11' ? <Image index={index} formDataUpData={this.formDataUpData} getItemAnswer={this.getItemAnswer} data={item}></Image> :
                                                        item.type === '12' ? <File index={index} formDataUpData={this.formDataUpData} getItemAnswer={this.getItemAnswer} data={item}></File> :
                                                            item.type === '13' ? <Cascader index={index} getItemAnswer={this.getItemAnswer} data={item}></Cascader> :
                                                                item.type === '15' ? <Child index={index} answer={forms[index].answer} addMenber={this.addMenber} getItemAnswer={this.getItemAnswer} data={item}></Child> : 'Empty'
                                )
                            }
                        </div>)}
                    </div>
                    <div className={styles.submit}>
                        <Button type='primary' style={{ width: '100%' }} onClick={this.makeSure}>提交</Button>
                    </div>
                </div>
                {showAddPage ?
                    <div className={styles.addMenberBox}>
                        <AddMenber
                            hideAddPage={this.hideAddPage}
                            addKey={addKey}
                            questions={questions[addIndex].childrens}
                            addMenberInfo={this.addMenberInfo}
                        >

                        </AddMenber>
                    </div> : ''
                }
                <Modal
                    title="确认"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    提交后不可更改，是否提交
                </Modal>
            </Spin >
        )
    }
}