import React, { Component } from 'react'
import styles from './index.module.css'

import gobackImg from '../../assets/images/goback.png'

export default class Explain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            url: '',
            contentHeight: 0,
            time: 10,
            cango: false,
            area:'',
            name:''
        }
    }
    componentDidMount() {
        const url = sessionStorage.getItem('openWeb')
        const contentHeight = window.innerHeight - 98
        this.setState({
            contentHeight: contentHeight,
            url:url,
            area:this.props.match.params.id,
            name:this.props.match.params.name,
        })
        this.timer = setInterval(this.outTime, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }
    
    outTime = () => {
        let t = this.state.time
        t--
        this.setState({
            time: t
        })
        if (t === 0) {
            clearInterval(this.timer)
            this.setState({
                cango: true
            })
        }
    }

    goEnter = () => {
        if (this.state.cango) {
            this.props.history.push(`/AllBox/${this.props.match.params.id}/${this.props.match.params.name}`)
        }
    }

    goback =()=>{
        this.props.history.go(-1)
    }

    render() {
        const { url, contentHeight, time, cango } = this.state
        return (
            <div className={styles.body}>
                <div className={styles.header}>
                    <div className={styles.gobackImgBox} onClick={this.goback}>
                        <img src={gobackImg} alt='' />
                    </div>
                    <span>在线报名说明</span>
                </div>
                <div style={{ height: contentHeight, overflow: 'auto' }}>
                    <iframe src={url} title='news' style={{ width: '100%', height: '100%',border:'0px solid #ccc' }}></iframe>
                </div>
                <div className={styles.goWrite} onClick={this.goEnter}>
                    <span style={{ backgroundColor: cango ? '#449bfe' : '#999' }}>我已详细阅读并了解填报内容<i style={{ display: time === 0 ? 'none' : 'inline-block' }}>({time}s)</i></span>
                </div>
            </div>
        )
    }
}