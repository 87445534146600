import React, { Component } from 'react'
import { Picker, List } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css';

export default class LongRadioMobile extends Component {
    constructor(props) {
        super(props)
        // console.log(this.props.data)
        this.state = {
            index: this.props.index,
            data: this.props.data,
            desc: this.props.data.desc,
            options: this.props.data.options,
            choosed: '',
            require: this.props.data.require,
            isWrite: this.props.isWrite
        }
    }

    componentDidMount() {
        this.setState({
            choosed: this.props.answer
        }, () => this.dataUpload())
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ isWrite: nextProps.isWrite, choosed: nextProps.answer })
    }

    choosedChange = (e) => {
        this.setState({
            choosed: e[0]
        }, () => this.dataUpload())
    }

    dataUpload = () => {
        const { index, choosed } = this.state
        const data = {
            key: this.state.data.key,
            type: this.state.data.type,
            answer: choosed
        }
        this.props.getItemAnswer(data, index)
    }

    render() {
        const { desc, options, choosed, require, isWrite } = this.state;
        let choosedArr = [];
        if (choosed !== '') {
            choosedArr.push(choosed)
        }
        const datas = [];
        if (options.length > 0) {
            options.forEach(item => {
                let newItem = {};
                newItem.label = item.desc;
                newItem.value = item.id;
                datas.push(newItem);
            })
        }
        return (
            <Picker data={datas} cols={1} disabled={!isWrite} value={choosedArr} onChange={this.choosedChange}>
                <List.Item arrow="horizontal">
                    <div>
                        <span style={{ color: 'red', display: require === '1' ? 'inline-block' : 'none', marginRight: '5px' }}>*</span>
                        <span style={{ color: '#000', fontSize: '14px' }}>{desc}</span>
                    </div>
                </List.Item>
            </Picker>
        )
    }
}