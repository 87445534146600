import React, { Component } from 'react'
import { axiosPost } from '../../Utils/RequestUtil'
import { Select, Spin } from 'antd'
import styles from './index.module.css'
import update from 'immutability-helper'

const { Option } = Select

export default class Cascader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            answer: this.props.answer === undefined ? '' : this.props.answer,
            index: this.props.index,
            data: this.props.data,
            desc: this.props.data.desc,
            levels: this.props.data.levels,
            require: this.props.data.require,
            renderData: [],
            loading: false,
            isWrite:this.props.isWrite
        }
    }

    componentDidMount() {
        // console.log(this.props)
        if (this.props.answer !== '') {
            this.getWriteAnswer(this.props.answer)
        } else {
            this.getCascader()
        }

    }

    getWriteAnswer = (e) => {
        const levels = this.props.data.levels
        const data = this.props.data
        const selecteds = e.split(',')

        const params = {
            flag: data.flag,
            parent: data.startId
        }
        this.setState({
            loading:true
        })
        axiosPost(14, params, 'Data', (res) => {
            this.setState({
                loading:false
            })
            if (res.code === 0) {
                let newRenderData = []
                for (let i = 0; i < levels.length; i++) {
                    const newData = update(levels[i], { options: { $set: i === 0 ? res.data.list : [] } })
                    const newData2 = update(newData, { selected: { $set: selecteds[i] } })
                    newRenderData.push(newData2)
                }
                for (let j = 0; j < selecteds.length - 1; j++) {
                    const params1 = {
                        flag: data.flag,
                        parent: selecteds[j]
                    }
                    this.setState({
                        loading:true
                    })
                    axiosPost(14, params1, 'Data', (res) => {
                        this.setState({
                            loading:false
                        })
                        if (res.code === 0) {
                            newRenderData[j + 1].options = res.data.list
                        }
                        let hasOptions = true
                        for (let k = 0; k < newRenderData.length; k++) {
                            if (newRenderData[k].options.length === 0) {
                                hasOptions = false
                            }
                        }
                        if (hasOptions) {
                            this.setState({
                                renderData: newRenderData
                            })
                        }
                    })
                }
            }
        })
    }

    getCascader = () => {
        const levels = this.props.data.levels
        const data = this.props.data
        const params = {
            flag: data.flag,
            parent: data.startId
        }
        this.setState({
            loading: true
        })
        axiosPost(14, params, 'Data', (res) => {
            this.setState({
                loading: false
            })
            let newRenderData = []
            if (res.code === 0) {
                for (let i = 0; i < levels.length; i++) {
                    const newData = update(levels[i], { options: { $set: i === 0 ? res.data.list : [] } })
                    const newData2 = update(newData, { selected: { $set: '' } })
                    newRenderData.push(newData2)
                }
                this.setState({
                    renderData: newRenderData
                })
            }
        })
    }

    selectChange = (e, index) => {
        const { renderData, data } = this.state
        if (index + 1 < renderData.length) {
            const params = {
                flag: data.flag,
                parent: e
            }
            this.setState({
                loading: true
            })
            axiosPost(14, params, 'Data', (res) => {
                this.setState({
                    loading: false
                })
                let newData = []
                for (let i = 0; i < renderData.length; i++) {
                    if (i > index) {
                        const newData1 = update(renderData[i], { options: { $set: [] } })
                        const newData2 = update(newData1, { selected: { $set: '' } })
                        newData.push(newData2)
                    } else {
                        newData.push(renderData[i])
                    }
                }
                const newData1 = update(newData[index], { selected: { $set: e } })
                const newData2 = update(newData[index + 1], { options: { $set: res.data.list } })
                const newRenderData = update(newData, { $splice: [[index, 1, newData1], [index + 1, 1, newData2]] })
                this.setState({
                    renderData: newRenderData
                }, () => this.dataUpload())
            })
        } else {
            const newData = update(renderData[index], { selected: { $set: e } })
            const newData1 = update(renderData, { $splice: [[index, 1, newData]] })
            this.setState({
                renderData: newData1
            }, () => this.dataUpload())
        }
    }

    dataUpload = () => {
        const { renderData, index } = this.state
        let answer = ''
        for (let i = 0; i < renderData.length; i++) {
            if (i !== renderData.length - 1) {
                answer += renderData[i].selected + ','
            } else {
                answer += renderData[i].selected
            }
        }
        const data = {
            key: this.state.data.key,
            type: this.state.data.type,
            answer: answer
        }
        this.props.getItemAnswer(data, index)
    }

    render() {
        const { desc, renderData, require,isWrite } = this.state
        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <div className={styles.title}>
                        <span style={{ color: 'red', display: require === '1' ? 'inline-block' : 'none', marginRight: '5px' }}>*</span>
                        <span>{desc}</span>
                    </div>
                    <div>
                        {renderData.map((item, index) => <div key={item.level}>
                            <span className={styles.selectTitle}>{item.name}</span>
                            <Select disabled={!isWrite} size='small' style={{ width: '200px' }} value={item.selected} onChange={(e) => this.selectChange(e, index)}>
                                {item.options.map(option => <Option key={option.id} value={option.id}>{option.desc}</Option>)}
                            </Select>
                        </div>)}
                    </div>
                </Spin>
            </div>
        )
    }
}