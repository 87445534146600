import React, { Component } from 'react'
import styles from './index.module.css'
import {message} from 'antd'
import update from 'immutability-helper'
import uploadImg from '../../assets/images/upload.png'
import deleteImg from '../../assets/images/delete.png'

export default class Image extends Component {
    constructor(props) {
        super(props)
        this.state = {
            answer: this.props.answer,
            index: this.props.index,
            data: this.props.data,
            desc: this.props.data.desc,
            count: this.props.data.count,
            require: this.props.data.require,
            answerUrls: [],
            imgUrls: [],
            files: [],
            bigUrl: '',
            contentHeight: 0,
            isWrite: this.props.isWrite
        }
    }

    componentDidMount() {
        // console.log(window.innerHeight)
        const contentHeight = window.innerHeight
        const answer = this.props.answer === '' ? [] : this.props.answer.split(',')
        console.log(answer)
        this.setState({
            answerUrls: answer,
            contentHeight: contentHeight
        }, () => this.dataUpload())
    }

    show = (file) => {
        if (file.target.files.length > 0) {
            const countArr = this.state.count.split(',')
            console.log(countArr)
            let newFiles = file.target.files
            if (countArr[1] === '1') {
                let itemUrl = URL.createObjectURL(newFiles[0])
                console.log(itemUrl)
                let newUrl = []
                newUrl.push(itemUrl)
                this.setState({
                    imgUrls: newUrl
                })

                this.props.formDataUpData(newFiles, this.state.data.key)

                const data = {
                    key: this.state.data.key,
                    type: this.state.data.type,
                    answer: ''
                }
                this.props.getItemAnswer(data, this.state.index)
                this.setState({
                    answer: ''
                })

            } else {
                let newUrls = this.state.imgUrls
                for (let i = 0; i < newFiles.length; i++) {
                    let itemUrl = URL.createObjectURL(newFiles[i])
                    newUrls.push(itemUrl)
                }
                let upFiles = [...this.state.files, ...newFiles]

                if (upFiles.length > parseInt(countArr[1])) {
                    message.warning('数量超过最大值，自动删除超过的部分')
                    newUrls = newUrls.slice(0, parseInt(countArr[1]))
                    upFiles = upFiles.slice(0, parseInt(countArr[1]))
                }
                this.setState({
                    imgUrls: newUrls,
                    files: upFiles
                })

                this.props.formDataUpData(upFiles, this.state.data.key)
            }
        }
    }

    dataUpload = () => {
        const { index, answerUrls } = this.state
        const newAnswerUrls = answerUrls.join(',')
        const data = {
            key: this.state.data.key,
            type: this.state.data.type,
            answer: newAnswerUrls
        }
        this.props.getItemAnswer(data, index)
    }

    imgClick = (url) => {
        this.setState({
            bigUrl: url
        })
    }
    hideBigImg = (e) => {
        e.stopPropagation()
        this.setState({
            bigUrl: ''
        })
    }

    delete = (flag, index) => {
        if (flag === 'answer') {
            const newAnswerUrls = update(this.state.answerUrls, { $splice: [[index, 1]] })
            const newAnswer = newAnswerUrls.join(',')
            this.setState({
                answerUrls: newAnswerUrls,
                answer: newAnswer
            })
            const data = {
                key: this.state.data.key,
                type: this.state.data.type,
                answer: newAnswer
            }
            this.props.getItemAnswer(data, this.state.index)
        } else {
            const newUrls = update(this.state.imgUrls, { $splice: [[index, 1]] })
            const newFiles = update(this.state.files, { $splice: [[index, 1]] })
            this.setState({
                imgUrls: newUrls,
                files: newFiles
            })
            this.props.formDataUpData(newFiles, this.state.data.key)
        }
    }

    render() {
        const { desc, count, require, answerUrls, imgUrls, bigUrl, contentHeight, isWrite } = this.state
        const countArr = count.split(',')
        return (
            <div>
                <div className={styles.title}>
                    <span style={{ color: 'red', display: require === '1' ? 'inline-block' : 'none', marginRight: '5px' }}>*</span>
                    <span>{desc}</span>
                    <span style={{ float: 'right' }}>数量{`${countArr[0]}~${countArr[1]}`}</span>
                </div>
                <div className={styles.uploadBox} style={{ display: isWrite ? 'block' : 'none' }}>
                    <input disabled={!isWrite} multiple={parseInt(countArr[1]) > 1 ? 'multiple' : ''} className={styles.inputFile} type='file' accept="image/*" onChange={(e) => this.show(e)} />
                    <img src={uploadImg} alt='' />
                </div>
                <div className={styles.imgBox} style={{ marginTop: '10px' }}>
                    <ul>
                        {answerUrls.map((item, index) => <li key={index}>
                            <div className={styles.delete} onClick={() => this.delete('answer', index)} style={{ display: isWrite ? 'block' : 'none' }}>
                                <img src={deleteImg} alt='' />
                            </div>
                            <img src={item} alt='' onClick={() => this.imgClick(item)} />
                        </li>)}
                        {imgUrls.map((item, index) => <li key={index}>
                            <div className={styles.delete} onClick={() => this.delete('img', index)} style={{ display: isWrite ? 'block' : 'none' }}>
                                <img src={deleteImg} alt='' />
                            </div>
                            <img src={item} alt='' onClick={() => this.imgClick(item)} />
                        </li>)}
                    </ul>
                </div>
                <div className={styles.showBigImg} style={{ display: bigUrl ? 'flex' : 'none', height: contentHeight, alignItems: 'center' }} onClick={this.hideBigImg}>
                    <img src={bigUrl} style={{ width: '100%', overflow: 'auto' }} alt='' />
                </div>
            </div>
        )
    }
}