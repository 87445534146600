import React, { Component } from 'react'
import { validateIdCard, validateMobilePhone } from '../../assets/regs/regs'
import { axiosPost } from '../../Utils/RequestUtil'
import { Input, Button, message, Spin, Row, Col } from 'antd'
import styles from './index.module.css'

import gobackImg from '../../assets/images/goback.png'
import yifenpeiImg from '../../assets/images/yifenpei.png'
import isWriteImg from '../../assets/images/isWrite.png'
import fenpeiImg from '../../assets/images/fenpei.png'
import warningImg from '../../assets/images/warning.png'

export default class AllBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            area: this.props.match.params.area,
            name: '',
            cardId: '',
            username: '',
            telephone: '',
            index: 0,
            size: 10,
            dataList: [],
            listKey: '',
            approvalList: [],
            fenpeiData: [],
            showFenpei: false,
            loading: false,
            isOpen: false
        }
    }

    componentDidMount() {
        this.setState({
            area: this.props.match.params.area,
            name: this.props.match.params.name,
        }, () => {
            const params = {
                area: this.state.area,
            }
            this.setState({
                loading: true
            })
            axiosPost(19, params, 'Form', (res) => {
                this.setState({
                    loading: false,
                })
                if (res.code === 0) {
                    this.setState({
                        isOpen: true,
                    })
                }
                else if (res.code === 1) {
                    this.setState({
                        isOpen: false,
                    })
                }
                else {
                    this.setState({
                        isOpen: false,
                    })
                    message.destroy();
                    message.info(res.message)
                }
            })
        })
    }

    tabsClick = (e) => {
        this.setState({
            activeKey: e
        })
    }

    goback = () => {
        this.props.history.go(-1)
    }

    usernameChange = (e) => {
        this.setState({
            username: e.target.value
        })
    }

    telephoneChange = (e) => {
        this.setState({
            telephone: e.target.value
        })
    }

    cardIdChange = (e) => {
        this.setState({
            cardId: e.target.value
        })
    }

    goCheck = () => {
        const { cardId, index, area, size, username, telephone } = this.state
        const openId = sessionStorage.getItem('openId')
        var validateCardId = validateIdCard(cardId)
        var validatePhone = validateMobilePhone(telephone)
        if (!validatePhone) {
            message.error('电话号码不正确，请仔细填写');
            return;
        }
        if (!validateCardId) {
            message.error('身份证不正确，请仔细填写');
            return;
        }
        const params = {
            area: area,
            start: '',
            end: '',
            state: '',
            id: cardId,
            telephone: telephone,
            name: username,
            index: index,
            size: size,
            openid: openId
        }
        this.setState({
            loading: true
        })
        axiosPost(3, params, 'Form', (res) => {
            this.setState({
                loading: false,
            })
            if (res.code === 0) {
                this.setState({
                    dataList: res.data.list,
                    listKey: res.data.list[0].key,
                    approvalList: []
                })
                const listKey = res.data.list[0].key
                const params2 = {
                    area: area,
                    key: listKey,
                }
                // console.log(params2)
                this.setState({
                    loading: true
                })
                axiosPost(5, params2, 'Form', (res) => {
                    // console.log(res)
                    this.setState({
                        loading: false
                    })
                    if (res.code === 0) {
                        this.setState({
                            approvalList: res.data.list
                        })
                    }
                })
            } else {
                this.setState({
                    dataList: [],
                    listKey: '',
                    approvalList: []
                })
                message.info('没有找到对应的数据')
            }
        })
    }

    goLookHistory = () => {
        this.props.history.push(`/writeHistory/${this.state.listKey}/${this.state.area}/${this.state.dataList[0].state}/${this.state.dataList[0].title}`)
    }

    goMyWrite = () => {
        this.props.history.push(`/writeHistory/zzdjxxb/${this.state.area}/00/自主登记信息表`)
    }

    goLookFenpei = () => {
        const { area, listKey } = this.state
        this.setState({
            showFenpei: true
        })
        // message.info('暂未分配')
        const params = {
            area: area,
            key: listKey
        }
        this.setState({
            loading: true
        })
        axiosPost(6, params, 'Form', (res) => {
            // console.log(res)
            this.setState({
                loading: false
            })
            if (res.code === 0) {
                this.setState({
                    fenpeiData: res.data.list
                })
            }
        })
    }

    hideFenpei = () => {
        this.setState({
            showFenpei: false
        })
    }

    fenpeiInfo = (e) => {
        e.stopPropagation()
    }

    render() {
        const { cardId, approvalList, dataList, name, username, telephone, fenpeiData, showFenpei, isOpen, area } = this.state
        return (
            <div className={styles.body} style={showFenpei ? { height: '100%', width: '100%', position: 'absolute', left: '0', top: '0', overflow: 'hidden' } : {}}>
                <Spin spinning={this.state.loading}>
                    <div className={styles.father}>
                        <div className={styles.headerBgc}>
                            <div className={styles.gobackImgBox} onClick={this.goback}>
                                <img src={gobackImg} alt='' />
                            </div>
                            <span className={styles.title}>{name}</span>
                        </div>
                        <div className={styles.tabsBox}>
                            <div>审批查询</div>
                        </div>
                    </div>
                    <div className={styles.child}>
                        <div className={styles.inputBox}>
                            <Input size='large' value={username} onChange={this.usernameChange} placeholder="请输入姓名" />
                        </div>
                        <div className={styles.inputBox}>
                            <Input size='large' value={telephone} onChange={this.telephoneChange} placeholder="请输入电话号码" />
                        </div>
                        <div className={styles.inputBox}>
                            <Input size='large' value={cardId} onChange={this.cardIdChange} placeholder="请输入登记身份证" />
                        </div>
                        <div>
                            <Button type='primary' size='large' style={{ width: '100%' }} onClick={this.goCheck}>查询审批状态</Button>
                        </div>
                        <div className={styles.approvalInfo} style={{ display: dataList.length === 0 ? 'none' : 'block' }}>
                            {
                                area === '522725000'
                                    ? <>
                                        <div className='info' style={{ marginBottom: '10px',marginTop:'10px',padding:'10px',border:'1px solid #eee',borderRadius:'10px',width:'100%',backgroundColor:'#f0f0f0',lineHeight:'25px' }}>
                                            <Row gutter={10}>
                                                <Col span={8} style={{fontWeight:'bold'}}>学生姓名</Col>
                                                <Col span={16} style={{textAlign:'right'}}>{dataList.length > 0 ? dataList[0].publisher : ''}</Col>
                                            </Row>
                                            <Row gutter={10}>
                                            <Col span={8} style={{fontWeight:'bold'}}>家长姓名</Col>
                                                <Col span={16} style={{textAlign:'right'}}>{dataList.length > 0 ? dataList[0].hostName+'/'+dataList[0].telephone : ''}</Col>
                                            </Row>
                                            <Row gutter={10}>
                                                <Col span={8} style={{fontWeight:'bold'}}>学生身份证号</Col>
                                                <Col span={16} style={{textAlign:'right'}}>{dataList.length > 0 ? dataList[0].idcard : ''}</Col>
                                            </Row>
                                            <Row gutter={10}>
                                                <Col span={8} style={{fontWeight:'bold'}}>户籍地址</Col>
                                                <Col span={16} style={{textAlign:'right'}}>{dataList.length > 0 ? dataList[0].address : ''}</Col>
                                            </Row>
                                            <Row gutter={10}>
                                                <Col span={8} style={{fontWeight:'bold'}}>现居地址</Col>
                                                <Col span={16} style={{textAlign:'right'}}>{dataList.length > 0 ? dataList[0].nowAddress.replace(',','').replace(',','').replace(',','') : ''}</Col>
                                            </Row>
                                        </div>
                                        <div className={styles.ckfpxx} style={{ width: '100%' }} onClick={this.goLookFenpei}>
                                            <div className={styles.imgBox}>
                                                <img src={fenpeiImg} alt='' />
                                            </div>
                                            <div>点击查看分配信息</div>
                                        </div>
                                    </>
                                    : <div className={styles.approvalState}>
                                        <div className={styles.approvalTitle}>
                                            {/* 审批状态 */}
                                            <div>{dataList.length > 0 ? dataList[0].stateText : ''}</div>
                                            <span className={styles.warnInfo}>{dataList.length > 0 ? dataList[0].state === '00' ? '请尽快填写信息并提交' : dataList[0].state === '03' ? '请根据驳回原因重新填写信息并提交' : '' : ''}</span>
                                        </div>
                                        <div className={styles.approvalStateBox}>
                                            <div className={styles.ckytbxx} onClick={this.goLookHistory}>
                                                <div className={styles.imgBox}>
                                                    <img src={isWriteImg} alt='' />
                                                </div>
                                                <span>
                                                    {dataList.length > 0 ? dataList[0].state === '00' ? '点击前往填写信息' : dataList[0].state === '03' ? '点击前往修改信息' : '点击查看信息' : ''}
                                                </span>
                                            </div>
                                            <div className={styles.ckfpxx} onClick={this.goLookFenpei}>
                                                <div className={styles.imgBox}>
                                                    <img src={fenpeiImg} alt='' />
                                                </div>
                                                <div>查看分配信息</div>
                                            </div>
                                        </div>
                                        <div className={styles.toastInfo}>
                                            提示：审批中、已审批只能查看填报信息。待补充、驳回
                                            时请点击上方【查看已填报信息】并根据驳回原因
                                            重新修改提交
                                        </div>
                                    </div>
                            }
                            <div className={styles.approvalStateList} style={{ display: approvalList.length > 0 ? 'block' : 'none' }}>
                                <h2>当前状态</h2>
                                <div className={styles.statusSuccess}>
                                    <span className={styles.time}>{approvalList.length > 0 ? approvalList[0].time : ''}</span>
                                    <div className={styles.status}>
                                        <span style={{ marginRight: '15px' }}>{approvalList.length > 0 ? approvalList[0].resultText : ''}</span>
                                        {approvalList.length > 0 ? approvalList[0].result === '03' ? <span className={styles.reason}>{approvalList[0].reason}</span> : '' : ''}
                                    </div>
                                </div>
                                <h2>历史状态</h2>
                                <div className={styles}>
                                    {approvalList.length > 0 ? approvalList.map((item, index) =>
                                        index !== 0 ?
                                            <div className={styles.statusBox} key={item.key}>
                                                <span className={styles.time}>{item.time}</span>
                                                <div className={styles.status}>
                                                    <span style={{ marginRight: '10px' }} className={styles.statusHistory}>{item.resultText}</span>
                                                    {item.result === '03' ? <span className={styles.reason}>{item.reason}</span> : ''}
                                                </div>
                                            </div> : ''

                                    ) : <div style={{ backgroundColor: '#eee', lineHeight: '30px' }}>暂无历史状态</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.fenpeiFullBox} onClick={this.hideFenpei} style={{ display: showFenpei ? 'block' : 'none' }}>
                        <div className={styles.fenpeiResult} onClick={this.fenpeiInfo}>
                            <div className={styles.fenpeiTitle}>
                                <h2>分配信息</h2>
                                <span>请以最新一条分配记录为准</span>
                            </div>
                            <div className={styles.fenpeiInfo}>
                                {
                                    fenpeiData.length > 0 ? <ul>
                                        {fenpeiData.map(item => <li key={item.key}>
                                            <div className={styles.iconBox}>
                                                <img src={yifenpeiImg} alt='' />
                                            </div>
                                            <div className={styles.resultTimeName}>
                                                <div className={styles.resultTime}>
                                                    <span>{item.resultText}<i>{item.reason}</i></span>
                                                    <span>{item.time}</span>
                                                </div>
                                                <h2>{item.schoolName}</h2>
                                            </div>
                                        </li>)}
                                    </ul> : '暂无分配信息'
                                }
                            </div>
                        </div>
                    </div>
                    {
                        isOpen ? <div style={{ marginTop: '15px', padding: '20px', display: dataList.length === 0 ? 'block' : 'none' }}>
                            <span style={{ fontSize: '12px', color: 'red' }}>
                                <img src={warningImg} alt='' style={{ height: '12px', marginRight: '3px', verticalAlign: 'middle', marginBottom: '2px' }} />
                                如未到校进行预登记，请点击下方按钮进行自助登记
                            </span>
                            <Button onClick={this.goMyWrite} type='primary' style={{ width: "100%", height: '40px', fontSize: '16px' }}>自助登记</Button>
                        </div> : null
                    }
                </Spin>
            </div>
        )
    }
}

