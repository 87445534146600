import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import Home from './pages/Home/index.js'
import Explain from './pages/Explain/index.js'
import Index from './pages/Index/index.js'
import WriteHistory from './pages/WriteHistory/index.js'
import AllBox from './pages/AllBox/index.js'
import ShowImgFile from './pages/ShowImgFile/index.js'
import Complete from './pages/Complete/index'
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('en')


function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <Router>
        <div className="App">
          <Route path='/' exact render={() => <Redirect to='/index' />} />
          <Route path='/explain/:id/:name' component={Explain} />
          <Route path='/index' component={Index} />
          <Route path='/home' component={Home} />
          <Route path='/writeHistory/:key/:area/:status/:title' component={WriteHistory} />
          <Route path='/AllBox/:area/:name' component={AllBox} />
          <Route path='/showImgFile' component={ShowImgFile} />
          <Route path='/complete' component={Complete} />
        </div>
      </Router>
    </ConfigProvider>

  );
}

export default App;
