import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'core-js/es'  
import 'react-app-polyfill/ie9'  
import 'react-app-polyfill/stable'

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
