let quality = 0.5;

export async function fileResizetoFile(file, callback) {
    let index = 0;
    function fn(res, file, quality) {
        let newquality = quality;
        filetoDataURL(file, function (dataurl) {
            dataURLtoImage(dataurl, function (image) {
                canvasResizetoFile(imagetoCanvas(image), newquality, (res) => {
                    let files = {}
                    files = new window.File(
                        [res],
                        file.name,
                        { type: res.type }
                    );
                    if (index < 5 && res.size > 100 * 1024) {
                        index++;
                        fn(res, files, newquality)
                    } else {
                        return callback(files)
                    }
                });
            })
        })
    }

    let types = file.type.split('/')
    let type = types[0]
    if (type === 'image') {
        if (file.size > 100 * 1024) {
            try {
                filetoDataURL(file, function (dataurl) {
                    dataURLtoImage(dataurl, function (image) {
                        canvasResizetoFile(imagetoCanvas(image), quality, (res) => {
                            let files = {}
                            files = new window.File(
                                [res],
                                file.name,
                                { type: res.type }
                            );
                            if (res.size > 100 * 1024) {
                                fn(res, files, quality)
                            } else {
                                return callback(files)
                            }
                        });
                    })
                })
            } catch (e) {
                alert(e);
            }
        } else {
            return callback(file)
        }
    } else {
        return callback(file)
    }

}


function filetoDataURL(file, fn) {
    var reader = new FileReader();
    reader.onloadend = function (e) {
        fn(e.target.result);
    };
    reader.readAsDataURL(file);

}

function dataURLtoImage(dataurl, fn) {
    var img = new Image();
    img.onload = function () {
        fn(img);
    };
    img.src = dataurl;
}

function canvasResizetoFile(canvas, quality, fn) {
    canvas.toBlob(function (blob) {
        fn(blob);
    }, 'image/jpeg', quality);
}

function imagetoCanvas(image) {
    var cvs = document.createElement("canvas");
    var ctx = cvs.getContext('2d');
    cvs.width = image.width;
    cvs.height = image.height;
    ctx.drawImage(image, 0, 0, cvs.width, cvs.height);
    return cvs;
}
