import React, { Component } from 'react'
import { Row, Col,Button } from 'antd'
import SignatureCanvas from 'react-signature-canvas'
import { RedoOutlined } from '@ant-design/icons'
import styles from './index.module.css'

export default class Sign extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: this.props.index,
            inputValue: '',
            require: '1',
        }
        this.clear = this.clear.bind(this);
        this.trim = this.trim.bind(this);
    }
    signCanvas = { clear: () => { }, toDataURL: (param) => { return "" } };
    componentDidMount() {
        this.setState({
            inputValue: this.props.answer
        })
    }
    clear() {
        this.signCanvas.clear();
    }
    trim() {
        return this.signCanvas.toDataURL('image/png')
    }
    render() {
        const { index, require } = this.state
        return (
            <div>
                <div className={styles.title}>
                    <span style={{ color: 'red', display: require === '1' ? 'inline-block' : 'none', marginRight: '5px' }}>*</span>
                    <span>签名</span>
                </div>
                <div>
                    <Row>
                        <Col span={24} key={index + 'sign'}>
                            <SignatureCanvas ref={(ref) => { this.signCanvas = ref }} penColor='black'
                                canvasProps={{ className: 'signCanvas' }} />
                        </Col>
                        <Col span={24} key={index + 'signbtn'} style={{marginTop:'10px'}}>
                            <Button icon={<RedoOutlined />} onClick={this.clear} type="default" style={{ width: '100%', height: '100%' }}>
                                重签
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}