import React, { Component } from 'react'
import styles from './index.module.css'

import gobackImg from '../../assets/images/goback.png'

export default class ShowImgFile extends Component {
    constructor() {
        super()
        this.state = {
            url: '',
            contentHeight: 0,
            fileStyle: '',
           
        }
    }

    componentDidMount() {
        const url = JSON.parse(sessionStorage.getItem('openImgFile'))
        console.log(url)
        const contentHeight = window.innerHeight - 50
        this.setState({
            url: url.url,
            contentHeight: contentHeight,
            fileStyle: url.type,
        })
    }
    

    goback = () => {
        this.props.history.go(-1)
    }

    render() {
        const { url, contentHeight, fileStyle } = this.state
        return (
            <div className={styles.body}>
                <div className={styles.header}>
                    <div className={styles.gobackImgBox} onClick={this.goback}>
                        <img src={gobackImg} alt='' />
                    </div>
                    <span onClick={this.goback}>返回</span>
                </div>
                {/* <div style={{ height: contentHeight, overflow: 'auto', display: 'flex', alignItems: 'center', backgroundColor: '#000' }}>
                        <img src={url} style={{ width: '100%', border: '0px solid #ccc' }} alt='' />
                    </div> */}
                {
                    fileStyle==='image' ? <div style={{ height: contentHeight, overflow: 'auto', display: 'flex', alignItems: 'center', backgroundColor: '#000' }}>
                        <img src={url} style={{ width: '100%', border: '0px solid #ccc' }} alt='' />
                    </div> : <div style={{ height: contentHeight, overflow: 'auto', width: '100%' }}>
                            <iframe src={url} title='news' style={{ width: '100%', height: '100%', border: '0px solid #ccc' }}></iframe>
                        </div>
                }
            </div>
        )
    }
}