import axios from 'axios';

const base = 'http://47.108.63.75:41712/api/';
// const base = 'http://localhost:1675/api/';
const config = { showLoading: true }
export async function axiosPost(action, params, api, callback) {
    let body = {
        action: action,
        parameters: params
    };
    return axios.post(`${base}/${api}`, body, config).then(res => {
        callback(res.data);
    });
}

export async function axiosGet( api, callback) {
    return axios.get(`${base}/${api}`, config).then(res => {
        callback(res.data);
    });
}

export async function axiosPUT(formData, api, callback) {
    
    return axios.put(`${base}/${api}`, formData, config).then(res => {
        callback(res.data);
    });
}