import React, { Component } from 'react'
import { Button, message } from 'antd'
import { validateIdCard, validateMobilePhone, validateEmail, validateNumber, validateChinese, validatePhone, validateBankId } from '../../assets/regs/regs'

import styles from './index.module.css'
import update from 'immutability-helper'

import Cascader from '../../components/Cascader/index'
import Child from '../../components/Child/index'
import Date from '../../components/Date/index'
import File from '../../components/File/index'
import LongRadio from '../../components/LongRadio/index'
import ShortRadio from '../../components/ShortRadio/index'
import ShortText from '../../components/ShortText/index'
import Image from '../../components/Image/index'

import gobackImg from '../../assets/images/goback.png'
import ShortTextMobile from '../../components/ShortTextMobile'
import DateMobile from '../../components/DateMobile'
import LongRadioMobile from '../../components/LongRadioMobile'
import ImageMobile from '../../components/ImageMobile'
import CascaderMobile from '../../components/CascaderMobile'
import ChildMobile from '../../components/ChildMobile'

export default class AddMenber extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addKey: this.props.addKey,
            questions: this.props.questions,
            answers: [],
            formdataData: [],
            lookChildIndex: this.props.lookChildIndex,
            isWrite:this.props.isWrite
        }
    }

    componentDidMount() {
        // document.documentElement.scrollTop = document.body.scrollTop = 0;
        let formdataData = []
        const questions = this.props.questions
        let answers = this.props.answers
        console.log(questions, answers)
        if (answers.length === 0) {
            for (let i = 0; i < questions.length; i++) {
                if (questions[i].type === '15') {
                    answers.push({
                        key: questions[i].key,
                        type: questions[i].type,
                        answer: []
                    })
                } else if (questions[i].type === '11' || questions[i].type === '12') {
                    formdataData.push({
                        index: questions[i].key,
                        files: []
                    })
                    answers.push({
                        key: questions[i].key,
                        type: questions[i].type,
                        answer: ''
                    })
                } else {
                    answers.push({
                        key: questions[i].key,
                        type: questions[i].type,
                        answer: ''
                    })
                }
            }
        }

        this.setState({
            answers: answers,
            formdataData: formdataData
        })
    }

    goback = () => {
        this.props.hideAddPage()
    }

    getItemAnswer = (e, index) => {
        const newAnswers = update(this.state.answers, { $splice: [[index, 1, e]] })
        if (newAnswers !== undefined) {
            this.setState({
                answers: newAnswers
            })
        }
    }

    addMenber = () => {
        const { answers, formdataData, questions } = this.state
        console.log(answers)
        console.log(formdataData)
        const keysArr = []
        for (let i = 0; i < questions.length; i++) {
            keysArr.push(questions[i].key)
        }
        for (let i = 0; i < answers.length; i++) {
            const itemKey = answers[i].key
            const itemAnswer = answers[i].answer
            const itemType = answers[i].type
            const itemIndex = keysArr.indexOf(itemKey)
            const isRequire = questions[itemIndex].require
            const validateType = questions[itemIndex].validate
            if (isRequire === '1') {
                if (itemType === '15') {
                    if (itemAnswer.length === 0) {
                        message.error(`${questions[itemIndex].desc}为必填项，暂未填写`)
                        return
                    }
                } else if (itemType === '11' || itemType === '12') {
                    const dataIndexArr = []
                    for (let i = 0; i < formdataData.length; i++) {
                        dataIndexArr.push(formdataData[i].index)
                    }
                    const answerIndex = dataIndexArr.indexOf(itemKey)
                    if (formdataData[answerIndex].files.length === 0) {
                        message.error(`${questions[itemIndex].desc}为必填项，暂未填写`)
                        return
                    }
                } else if (itemType === '13') {
                    const cascadeAnswer = itemAnswer.split(',')
                    for (let i = 0; i < cascadeAnswer.length; i++) {
                        if (cascadeAnswer[i] === '') {
                            message.error(`${questions[itemIndex].desc}需要填写完整`)
                            return
                        }
                    }
                } else {
                    if (itemAnswer === '') {
                        message.error(`${questions[itemIndex].desc}为必填项，暂未填写`)
                        return
                    } else {
                        let result
                        switch (validateType) {
                            case '02':
                                result = validateMobilePhone(itemAnswer)
                                break;
                            case '03':
                                result = validatePhone(itemAnswer)
                                break;
                            case '04':
                                result = validateIdCard(itemAnswer)
                                break;
                            case '05':
                                result = validateEmail(itemAnswer)
                                break;
                            case '06':
                                result = validateBankId(itemAnswer)
                                break;
                            case '07':
                                result = validateNumber(itemAnswer)
                                break;
                            case '08':
                                result = validateChinese(itemAnswer)
                                break;
                            default:
                                result = true
                                break;
                        }
                        console.log(result, itemKey, itemAnswer, validateType)
                        if (!result) {
                            message.error(`${questions[itemIndex].desc}填写格式不正确，请仔细检查`)
                            return
                        }
                    }
                }
            } else {
                if (itemAnswer !== '') {
                    let result
                    switch (validateType) {

                        case '02':
                            result = validateMobilePhone(itemAnswer)
                            break;
                        case '03':
                            result = validatePhone(itemAnswer)
                            break;
                        case '04':
                            result = validateIdCard(itemAnswer)
                            break;
                        case '05':
                            result = validateEmail(itemAnswer)
                            break;
                        case '06':
                            result = validateBankId(itemAnswer)
                            break;
                        case '07':
                            result = validateNumber(itemAnswer)
                            break;
                        case '08':
                            result = validateChinese(itemAnswer)
                            break;
                        default:
                            result = true
                            break;
                    }
                    console.log(result, itemKey, itemAnswer, validateType)
                    if (!result) {
                        message.error(`${questions[itemIndex].desc}填写格式不正确，请仔细检查`)
                        return
                    }
                }
            }
        }
        this.props.addMenberInfo(this.state.answers, this.state.addKey,this.state.lookChildIndex)
    }

    render() {
        const { questions, answers ,isWrite} = this.state
        return (
            <div className={styles.body}>
                <div className={styles.header}>
                    <div className={styles.imgBox} onClick={this.goback}>
                        <img src={gobackImg} alt='' />
                    </div>
                    <span>新增家庭成员</span>
                </div>
                <div className={styles.questionsBox}>
                    {questions.length > 0 && answers.length > 0 ? questions.map((item, index) => <div key={item.key}>
                        {
                            (
                                item.type === '01' ? <ShortTextMobile index={index} isWrite={isWrite} answer={answers[index].answer} getItemAnswer={this.getItemAnswer} data={item}></ShortTextMobile> :
                                    item.type === '03' ? <DateMobile index={index} isWrite={isWrite} answer={answers[index].answer} getItemAnswer={this.getItemAnswer} data={item}></DateMobile> :
                                        item.type === '06' ? <LongRadioMobile index={index} isWrite={isWrite} answer={answers[index].answer} getItemAnswer={this.getItemAnswer} data={item}></LongRadioMobile> :
                                            item.type === '08' ? <LongRadioMobile index={index} isWrite={isWrite} answer={answers[index].answer} getItemAnswer={this.getItemAnswer} data={item}></LongRadioMobile> :
                                                item.type === '11' ? <ImageMobile index={index} isWrite={isWrite} answer={answers[index].answer} getItemAnswer={this.getItemAnswer} data={item}></ImageMobile> :
                                                    item.type === '12' ? <File index={index} isWrite={isWrite} answer={answers[index].answer} getItemAnswer={this.getItemAnswer} data={item}></File> :
                                                        item.type === '13' ? <CascaderMobile index={index} isWrite={isWrite} answer={answers[index].answer} getItemAnswer={this.getItemAnswer} data={item}></CascaderMobile> :
                                                            item.type === '15' ? <ChildMobile index={index} isWrite={isWrite} answer={answers[index].answer} getItemAnswer={this.getItemAnswer} data={item}></ChildMobile> : 'Empty'
                            )
                        }
                    </div>) : ''}
                </div>
                <div className={styles.submit} style={{display:isWrite?'block':'none'}}>
                    <Button style={{ width: '100%' }} type='primary' onClick={this.addMenber}>添加成员</Button>
                </div>
            </div>
        )
    }
}