import React, { Component } from 'react'
import { List, InputItem } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
export default class ShortTextMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: this.props.index,
            data: this.props.data,
            desc: this.props.data.desc,
            inputValue: '',
            require: this.props.data.require,
            isWrite: this.props.isWrite
        }
    }

    componentDidMount() {
        this.setState({
            inputValue: this.props.answer
        }, () => this.dataUpload())
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ isWrite: nextProps.isWrite, inputValue: nextProps.answer })
    }

    onChange = (e) => {
        this.setState({
            inputValue: e
        }, () => this.dataUpload())
    }

    dataUpload = () => {
        const { index, inputValue } = this.state
        const data = {
            key: this.state.data.key,
            type: this.state.data.type,
            answer: inputValue
        }
        this.props.getItemAnswer(data, index)
    }

    render() {
        const { desc, inputValue, require, isWrite } = this.state
        return (
            <InputItem
                disabled={!isWrite}
                value={inputValue}
                style={{ textAlign: 'right' }}
                onChange={this.onChange}
                labelNumber={7}
                placeholder={"请输入" + desc}
            >
                <div>
                    <span style={{ color: 'red', display: require === '1' ? 'inline-block' : 'none', marginRight: '5px' }}>*</span>
                    <span style={{ color: '#000', fontSize: '14px' }}>{desc}</span>
                </div>
            </InputItem>
        )
    }
}