import React, { Component } from 'react'
import { axiosPost, axiosPUT, axiosGet } from '../../Utils/RequestUtil'
import { Select, Button, Row, Col, Spin, Input, DatePicker, message, Modal,Card,Divider,Alert,Empty } from 'antd'
import update from 'immutability-helper'
import styles from './index.module.css'
import moment from 'moment'

const dateFormat = 'YYYY-MM-DD'

const { Option } = Select

export default class Complete extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // schools: [],
            choosedSchool: '',
            classes: [],
            choosedClass: '',
            students: [],
            choosedStudent: '',
            fields: [],
            loading: false,
            studentInfo: [],
            fieldsAns: [],
            makeSureShow: false
        }
    }

    componentDidMount() {
        // this.getSchool()
        let enterprise = this.queryParam('enterprise')
        // let enterprise = "522725005"
        this.schoolChange(enterprise)
    }

    queryParam = (name) => {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    }


    // getSchool = () => {
    //     this.setState({
    //         loading: true
    //     })
    //     axiosGet('Enterprise', (res) => {
    //         this.setState({
    //             loading: false
    //         })
    //         if (res.code === 0) {
    //             this.setState({
    //                 schools: res.data
    //             })
    //         } else {
    //             message.warning('获取学校列表失败，请刷新页面重新获取')
    //         }
    //     })
    // }

    schoolChange = (e) => {
        this.getField(e)
        const params = {
            enterprise: e,
            flag: 'HYBM'
        }
        this.setState({
            choosedSchool: e,
            choosedClass: '',
            choosedStudent: '',
            studentInfo: [],
            loading: true
        })
        axiosPost(18, params, 'Data', (res) => {
            console.log(res)
            this.setState({
                loading: false
            })
            if (res.code === 0) {
                this.setState({
                    classes: res.data.list
                })
            } else {
                message.warning('获取班级列表失败，请刷新页面重新获取')
            }
        })
    }

    getField = (e) => {
        this.setState({
            loading: true
        })
        axiosGet(`Field/${e}`, (res) => {
            console.log(res)
            this.setState({
                loading: false
            })
            if (res.code === 0) {
                this.setState({
                    fields: res.data
                })
            } else {
                message.warning('获取信息字段失败，请刷新页面重新获取')
            }
        })
    }

    classChange = (e) => {
        const { choosedSchool } = this.state
        const params = {
            enterprise: choosedSchool,
            id: e,
            flag: 'HYBM'
        }
        this.setState({
            choosedClass: e,
            choosedStudent: '',
            studentInfo: [],
            loading: true
        })
        axiosPost(20, params, 'Data', (res) => {
            console.log(res)
            this.setState({
                loading: false
            })
            if (res.code === 0) {
                this.setState({
                    students: res.data.list
                })
            } else {
                message.warning('获取学生列表失败，请刷新页面重新获取')
            }
        })
    }

    studentChange = (e) => {
        this.setState({
            choosedStudent: e,
            studentInfo: []
        })
    }

    goSearch = () => {
        const { choosedSchool, choosedStudent, fields } = this.state
        const params = {
            enterprise: choosedSchool,
            key: choosedStudent
        }
        this.setState({
            loading: true
        })
        axiosPost(6, params, 'Student', (res) => {
            console.log(res)
            this.setState({
                loading: false
            })
            if (res.code === 0) {
                let info = res.data[0]
                let newField = []
                for (let i = 0; i < fields.length; i++) {
                    let keyWord = fields[i].TABLEID + fields[i].FieldID
                    console.log(info[keyWord])
                    newField.push({
                        id: keyWord,
                        value: info[keyWord]
                    })
                }
                this.setState({
                    studentInfo: res.data,
                    fieldsAns: newField
                })

            } else {
                message.warning('获取学生信息失败，请重新获取')
            }
        })
    }

    inputChang = (e, index) => {
        const { fieldsAns } = this.state
        let neFieldsAns = update(fieldsAns[index], { value: { $set: e.target.value } })
        let neFieldsAns1 = update(fieldsAns, { $splice: [[index, 1, neFieldsAns]] })
        this.setState({
            fieldsAns: neFieldsAns1
        })
    }
    selectChange = (e, index) => {
        const { fieldsAns } = this.state
        let neFieldsAns = update(fieldsAns[index], { value: { $set: e } })
        let neFieldsAns1 = update(fieldsAns, { $splice: [[index, 1, neFieldsAns]] })
        this.setState({
            fieldsAns: neFieldsAns1
        })
    }
    dateChange = (e, index) => {
        const { fieldsAns } = this.state
        let neFieldsAns = update(fieldsAns[index], { value: { $set: e } })
        let neFieldsAns1 = update(fieldsAns, { $splice: [[index, 1, neFieldsAns]] })
        this.setState({
            fieldsAns: neFieldsAns1
        })
    }

    submitClick = () => {
        this.setState({
            makeSureShow: true
        })
    }

    handleOk = () => {
        const { choosedSchool, fieldsAns, choosedStudent } = this.state
        this.setState({
            makeSureShow: false,
        })
        let newfieldsAns = update(fieldsAns, { $push: [{ id: 'JHXKEYA', value: choosedStudent }] })
        const params = {
            enterprise: choosedSchool,
            data: newfieldsAns
        }
        console.log(params)
        this.setState({
            loading: true
        })
        axiosPost(7, params, 'Student', (res) => {
            this.setState({
                loading: false
            })
            if (res.code === 0) {
                message.success('更新成功')
                this.setState({
                    choosedStudent: '',
                    studentInfo: []
                })
            }
        })
    }

    handleCancel = () => {
        this.setState({
            makeSureShow: false,
        })
    }


    render() {
        const {  classes, students, choosedSchool, choosedClass, choosedStudent, fields, studentInfo, fieldsAns, makeSureShow } = this.state
        return (
            <Spin spinning={this.state.loading}>
                    <div className={styles.body}>
                       
                        <Alert
      message="学生信息完善系统"
      description="请先依次选择学校、年级班别及姓名。点击查询按钮后，认真如实填写相关信息并提交。"
      type="info"
      showIcon
    />
                    </div>
                    <div style={{padding:'10px'}}>
                    <Card title={"请选择学生信息"}>
                        <Row gutter={20} style={{fontSize:'16px'}}>
                            {/* <Col xxl={6} xl={7} lg={7} >
                                <span style={{ marginRight: '10px' }}>请选择学校</span>
                                <Select style={{ width: '200px' }} value={choosedSchool} onChange={this.schoolChange}>
                                    {
                                        schools.map(item => <Option key={item.EnterpriseNO} value={item.EnterpriseNO}>{item.EnterpriseName}</Option>)
                                    }
                                </Select>
                            </Col> */}
                            <Col xxl={6} xl={7} lg={7}>
                                <span style={{ marginRight: '10px' }}>请选择班级</span>
                                <Select style={{ width: '200px' }} value={choosedClass} onChange={this.classChange} disabled={choosedSchool === '' ? true : false}>
                                    {
                                        classes.map(item => <Option key={item.CodeALLID} value={item.CodeALLID}>{item.CodeAllName}</Option>)
                                    }
                                </Select>
                            </Col>
                            <Col xxl={6} xl={7} lg={7}>
                                <span style={{ marginRight: '10px' }}>请选择学生</span>
                                <Select style={{ width: '200px' }} value={choosedStudent} onChange={this.studentChange} disabled={choosedClass === '' ? true : false}>
                                    {
                                        students.map(item => 
                                            item.name===''?'':<Option key={item.CodeALLID} value={item.CodeALLID}>{item.name===''?'':item.name}</Option>
                                        )
                                    }
                                </Select>
                            </Col>
                            <Col xxl={6} xl={7} lg={7}>
                                <Button type='primary' style={{ width: '100%' }} onClick={this.goSearch} disabled={choosedSchool !== '' && choosedClass !== '' & choosedStudent !== '' ? false : true}>查询</Button>
                            </Col>
                        </Row>
                    </Card>
                    </div>
                    <div style={{padding:'10px'}}>
                    <Card title={"请认真填写如下信息"}>
                        <Row gutter={20}>
                            {
                                fields.length > 0 && studentInfo.length > 0 ? fields.map((item, index) =>
                                    <Col span={12} key={item.FieldKey} style={{marginBottom:'10px'}}>
                                        <Row >
                                            <Col span={10} style={{ textAlign: 'right' }}>
                                                <span style={{fontSize:'16px'}}>{item.FieldName}：</span>
                                            </Col>
                                            {item.FieldType === 'C' ? item.SelectBS === '' ?
                                                <Col span={14}>
                                                    <Input value={fieldsAns[index].value} style={{ width: '100%' }} onChange={(e) => this.inputChang(e, index)} />
                                                </Col> :
                                                <Col span={14}>
                                                    <Select value={fieldsAns[index].value} style={{ width: '100%' }} onChange={(e) => this.selectChange(e, index)}>
                                                        {
                                                            item.CodeDatas.map(child => <Option key={child.CodeALLID} value={child.CodeALLID}>{child.CodeAllName}</Option>)
                                                        }
                                                    </Select>
                                                </Col> :
                                                <Col span={14}>
                                                    <DatePicker value={fieldsAns[index].value === '' || fieldsAns[index].value === null ? '' : moment(fieldsAns[index].value, dateFormat)} style={{ width: '100%' }} onChange={(date, dateString) => this.dateChange(dateString, index)} />
                                                </Col>}
                                        </Row>
                                    </Col>
                                ) : <div style={{width:'100%',textAlign:'center'}}><Empty description={"暂无数据，请先选择学生，并点击查询按钮"} /></div>
                            }
                        </Row>
                    </Card>
                    </div>
                    <div style={{ textAlign: 'center', display: fields.length > 0 && studentInfo.length > 0 ? 'block' : 'none',padding:'10px' }}>
                        <Button onClick={this.submitClick} type='primary' size="large" style={{width:'50%'}}>提交</Button>
                    </div>
                    <Modal
                        visible={makeSureShow}
                        title='提示'
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        确认提交吗？
                    </Modal>
            </Spin>
        )
    }
}