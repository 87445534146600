import React, { Component } from 'react'
import { List, DatePicker } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import moment from 'moment'

const dateFormat = 'YYYY-MM-DD'

export default class DateMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: this.props.index,
            data: this.props.data,
            desc: this.props.data.desc,
            time: '',
            require: this.props.data.require,
            isWrite: this.props.isWrite
        }
    }

    componentDidMount() {
        // console.log(this.props.answer)
        this.setState({
            time: this.props.answer
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ isWrite: nextProps.isWrite, time: nextProps.answer })
    }
    onChange = (date) => {
        this.setState({
            time: moment(date).format(dateFormat)
        }, () => this.dataUpload())
    }

    dataUpload = () => {
        const { index, time } = this.state
        const data = {
            key: this.state.data.key,
            type: this.state.data.type,
            answer: time
        }
        this.props.getItemAnswer(data, index)
    }

    render() {
        const { desc, require, time, isWrite } = this.state
        return (
            <div>
                {
                    time !== null && time && time !== '' ? <DatePicker
                        mode="date"
                        disabled={!isWrite}
                        inputReadOnly={true}
                        title={'请选择' + desc}
                        value={new Date(time + ' 00:00:00')}
                        onChange={this.onChange}
                    >
                        <List.Item arrow="horizontal"><div>
                            <span style={{ color: 'red', display: require === '1' ? 'inline-block' : 'none', marginRight: '5px' }}>*</span>
                            <span style={{ color: '#000', fontSize: '14px' }}>{desc}</span>
                        </div></List.Item>
                    </DatePicker> : <DatePicker
                        mode="date"
                        disabled={!isWrite}
                        inputReadOnly={true}
                        title={'请选择' + desc}
                        onChange={this.onChange}
                    >
                        <List.Item arrow="horizontal"><div>
                            <span style={{ color: 'red', display: require === '1' ? 'inline-block' : 'none', marginRight: '5px' }}>*</span>
                            <span style={{ color: '#000', fontSize: '14px' }}>{desc}</span>
                        </div></List.Item>
                    </DatePicker>
                }

            </div>
        )
    }
}