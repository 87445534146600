import React, { Component } from 'react'
import { message } from 'antd'
import styles from './index.module.css'
import update from 'immutability-helper'

import accountBookImg from '../../assets/images/accountBook.png'

export default class File extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: this.props.index,
            data: this.props.data,
            desc: this.props.data.desc,
            count: this.props.data.count,
            require: this.props.data.require,
            files: [],
            returnAnswers: [],
            isWrite: this.props.isWrite,
            answers:''
        }
    }

    componentDidMount() {
        const answers = this.props.answer.split(',')
        // console.log(answers)
        this.setState({
            returnAnswers: answers,
            answers: this.props.answer
        }, () => this.dataUpload())
    }

    callback = (res, file, quality) => {
        // let files = {}
        // files = new window.File(
        //     [res],
        //     'pic1',
        //     { type: 'image/jpeg' }
        // );
        // console.log(files)
        console.log(res.size)
        console.log(file)
        if (res.size > 100 * 1024) {
            let newquality = quality * 0.9
            this.fileResizetoFile(file, newquality, (res) => {
                this.callback(res, file, newquality)
            })
        } else {
            console.log(res)
            return res
        }
    }

    show = (e) => {
        const { count, files } = this.state
        const countArr = count.split(',')
        const AllFiles = [...files, ...e.target.files]
        this.setState({
            files: AllFiles
        })

        if (AllFiles.length > parseInt(countArr[1])) {
            message.info('数量超出限制')
        } else {
            this.props.formDataUpData(AllFiles, this.state.data.key)
        }
    }

    dataUpload = () => {
        const { index, answers } = this.state
        const data = {
            key: this.state.data.key,
            type: this.state.data.type,
            answer: answers
        }
        this.props.getItemAnswer(data, index)
    }

    deleteFiles = (index) => {
        const { files } = this.state
        const newFiles = update(files, { $splice: [[index, 1]] })
        this.setState({
            files: newFiles
        })
        this.props.formDataUpData(newFiles, this.state.data.key)
    }

    fileClick = (url) => {
        const imgStyles = ['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp']
        const ext = url.split('.')
        const extStr = ext[ext.length - 1]
        const isImg = imgStyles.indexOf(extStr)
        const urlData = {
            url: url,
            type: isImg > -1 ? 'image' : ''
        }

        sessionStorage.setItem('openImgFile', JSON.stringify(urlData))
        this.props.history.push('/showImgFile')
    }

    newFujian = (item) => {
        var getUrl = URL.createObjectURL(item);
        const ext = item.type.split('/')
        const extStr = ext[0]
        const urlData = {
            url: getUrl,
            type: extStr === 'image' ? 'image' : ''
        }
        sessionStorage.setItem('openImgFile', JSON.stringify(urlData))
        this.props.history.push('/showImgFile')
    }


    render() {
        const { desc, count, require, files, returnAnswers, isWrite } = this.state
        const countArr = count.split(',')
        return (
            <div>
                <div>
                    <div className={styles.title}>
                        <span style={{ color: 'red', display: require === '1' ? 'inline-block' : 'none', marginRight: '5px' }}>*</span>
                        <span>{desc}</span>
                        <span style={{ float: 'right' }}>数量{`${countArr[0]}~${countArr[1]}`}</span>
                    </div>
                    <div className={styles.uploadBox} style={{ display: isWrite ? 'block' : 'none' }}>
                        <input multiple={parseInt(countArr[1]) > 1 ? 'multiple' : ''} className={styles.inputFile} type='file' accept="image/*,text/*" onChange={(e) => this.show(e)} />
                        <img src={accountBookImg} alt='' />
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        {returnAnswers.map((item, index) => <div key={index} style={{ lineHeight: '30px' }}><span onClick={() => this.fileClick(item)}>附件{index + 1}</span></div>)}
                        {files.map((item, index) => <div key={index} style={{ lineHeight: '30px' }}><span onClick={() => this.newFujian(item)}>附件{index + returnAnswers.length + 1}</span><span className={styles.delete} onClick={() => this.deleteFiles(index)}>×</span></div>)}
                    </div>
                </div>
            </div>
        )
    }
}