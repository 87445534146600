import React, { Component } from 'react'
import { Select } from 'antd'
import styles from './index.module.css'

const { Option } = Select

export default class LongRadio extends Component {
    constructor(props) {
        super(props)
        // console.log(this.props.data)
        this.state = {
            index: this.props.index,
            data: this.props.data,
            desc: this.props.data.desc,
            options: this.props.data.options,
            choosed: '',
            require: this.props.data.require,
            isWrite:this.props.isWrite
        }
    }

    componentDidMount() {
        this.setState({
            choosed: this.props.answer
        }, () => this.dataUpload())
    }

    choosedChange = (e) => {
        this.setState({
            choosed: e
        }, () => this.dataUpload())
    }

    dataUpload = () => {
        const { index, choosed } = this.state
        const data = {
            key: this.state.data.key,
            type: this.state.data.type,
            answer: choosed
        }
        this.props.getItemAnswer(data, index)
    }

    render() {
        const { desc, options, choosed, require ,isWrite} = this.state
        return (
            <div>
                <div className={styles.title}>
                    <span style={{ color: 'red', display: require === '1' ? 'inline-block' : 'none', marginRight: '5px' }}>*</span>
                    <span>{desc}</span>
                </div>
                <div>
                    <Select disabled={!isWrite} value={choosed} style={{ width: '100%', zIndex: '888' }} onChange={this.choosedChange}>
                        {options.map(item =>
                            <Option key={item.id} value={item.id}>{item.desc}</Option>
                        )}
                    </Select>
                </div>
            </div>
        )
    }
}