import React, { Component } from 'react'
import { axiosPost } from '../../Utils/RequestUtil'
import { Spin } from 'antd'
import styles from './index.module.css'

export default class Index extends Component {
    constructor() {
        super()
        this.state = {
            areaDev: [],
            loading: false
        }
    }

    componentDidMount() {
        let openId = this.queryParam('openId')
        // let openId = 'o2wbbwBiQBDl9oWQwPbLoGhxPcSk'
        sessionStorage.setItem('openId', openId)
        this.getData()
    }

    queryParam = (name) => {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    }

    getData = () => {
        const params = {
            flag: 'BMLB'
        }
        this.setState({
            loading: true
        })
        axiosPost(6, params, 'Data', (res) => {
            // console.log(res)
            this.setState({
                loading:false
            })
            if (res.code === 0) {
                this.setState({
                    areaDev: res.data.list,
                })
            }
        })
    }

    goWrite = (e) => {
        this.props.history.push(`/explain/${e.CodeID}/${e.CodeName}`)
        sessionStorage.setItem('openWeb', e.CodeMemo)
    }
    render() {
        const { areaDev } = this.state
        return (
            <div className={styles.body}>
                <Spin spinning={this.state.loading}>
                    <div className={styles.header}>
                        <span>报名入口</span>
                    </div>
                    <div className={styles.toastInfo}>
                        请先选择报名类型
                </div>
                    <div className={styles.typeBox}>
                        {areaDev.map(item => <div key={item.CodeID} style={{marginBottom:'10px'}} className={styles.itemli} onClick={() => this.goWrite(item)}>{item.CodeName}</div>)}
                    </div>
                </Spin>
            </div>
        )
    }
}