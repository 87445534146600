import React, { Component } from 'react'
import { DatePicker } from 'antd'
import styles from './index.module.css'
import moment from 'moment'

const dateFormat = 'YYYY-MM-DD'

export default class Date extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: this.props.index,
            data: this.props.data,
            desc: this.props.data.desc,
            time: '',
            require: this.props.data.require,
            isWrite:this.props.isWrite
        }
    }

    componentDidMount(){
        // console.log(this.props.answer)
        this.setState({
            time:this.props.answer
        })
    }

    onChange = (date, dateString) => {
        this.setState({
            time: dateString
        }, () => this.dataUpload())
    }

    dataUpload = () => {
        const { index, time } = this.state
        const data = {
            key: this.state.data.key,
            type: this.state.data.type,
            answer: time
        }
        this.props.getItemAnswer(data, index)
    }

    render() {
        const { desc, require, time,isWrite } = this.state
        return (
            <div className={styles.body}>
                <div className={styles.title}>
                    <span style={{ color: 'red', display: require === '1' ? 'inline-block' : 'none', marginRight: '5px' }}>*</span>
                    <span>{desc}</span>
                </div>
                <DatePicker disabled={!isWrite} inputReadOnly={true} value={time===''?'':moment(time, dateFormat)} onChange={this.onChange} />
            </div>
        )
    }
}