import React, { Component } from 'react'
import { Input } from 'antd'
import styles from './index.module.css'

export default class ShortText extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: this.props.index,
            data: this.props.data,
            desc: this.props.data.desc,
            inputValue: '',
            require: this.props.data.require,
            isWrite:this.props.isWrite
        }
    }
    
    componentDidMount() {
        this.setState({
            inputValue: this.props.answer
        }, () => this.dataUpload())
    }
    
    onChange = (e) => {
        this.setState({
            inputValue: e.target.value
        }, () => this.dataUpload())
    }

    dataUpload = () => {
        const { index, inputValue } = this.state
        const data = {
            key: this.state.data.key,
            type: this.state.data.type,
            answer: inputValue
        }
        this.props.getItemAnswer(data, index)
    }

    render() {
        const { desc, inputValue, require ,isWrite} = this.state
        return (
            <div>
                <div className={styles.title}>
                    <span style={{ color: 'red', display: require === '1' ? 'inline-block' : 'none', marginRight: '5px' }}>*</span>
                    <span>{desc}</span>
                </div>
                <div>
                    <Input disabled={!isWrite} value={inputValue} onChange={this.onChange} />
                </div>
            </div>
        )
    }
}